import React from "react";
import { StaticWrapper, ContentWrapper, Bold, ContentParagraph } from "./promotionsHelpers";

const ManualPromotion = () => {
  return (
    <StaticWrapper $color="#fff">
      <ContentWrapper >
        <ContentParagraph color="#000">
          <Bold>🍁 ZŁOTY PAŹDZIERNIK 🍁</Bold> Z kodem KOCHAMGLINKI -15% na maseczki glinkowe. </ContentParagraph>
      </ContentWrapper>
    </StaticWrapper>
  );
};

export default ManualPromotion;
