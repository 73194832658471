import { adjustGiftPackingQuantity } from "../cart/giftPackingService";
import { cartSingleItemPush } from "../../utils/functions/dataLayerPush";
import * as storeService from "./storeService";
import { Action, CartPayload, CartState } from "../../utils/types/states";
import React, { Dispatch } from "react";
import { Cart, Order } from "../../utils/types/dto";
import { getOrder } from "./storeService";
import { IAPIError } from "@on/model-store";
import { isApiError } from "./commonService";
import { translate } from "../../utils/functions/translationComponent";
import { isPromotionAppliedOrApplicable } from "../promotion/renderPromotions";
import { translateApiError } from "../../utils/functions/translation";

function pollQueryStringParams() {
  const queryStringParams = new URLSearchParams(window.location.search);
  const cartRecoveryToken = queryStringParams.get("cart_recovery_token") ?? undefined;
  const registrationConfirmationToken = queryStringParams.get("registration_confirmation_token") ?? undefined;
  const smsToken = queryStringParams.get("sms") ?? undefined;

  if (cartRecoveryToken) {
    queryStringParams.delete("cart_recovery_token");
  }
  if (registrationConfirmationToken) {
    queryStringParams.delete("registration_confirmation_token");
  }
  if (smsToken) {
    queryStringParams.delete("sms");
  }

  if (cartRecoveryToken || registrationConfirmationToken || smsToken) {
    const urlWithoutQueryString = window.location.href.split("?")[0];
    const newUrl = `${urlWithoutQueryString}?${queryStringParams.toString()}`;
    window.history.replaceState({}, document.title, newUrl);
  }

  return { cartRecoveryToken, registrationConfirmationToken, smsToken };
}

export const fetchCart = async (dispatch: React.Dispatch<Action>) => {
  const { cartRecoveryToken, registrationConfirmationToken, smsToken } = pollQueryStringParams();

  let cart: CartPayload;
  if (registrationConfirmationToken || cartRecoveryToken || smsToken) {
    const response = await storeService.getCartWithToken({ registrationConfirmationToken, cartRecoveryToken, smsToken });
    if (isApiError(response)) {
      cart = await storeService.getCart();
      cart.globalMessage = {
        message: translateApiError(response),
        type: "ERROR",
      };
    } else {
      cart = response;
      cart.globalMessage = {
        message: translate({ key: registrationConfirmationToken ? "account.confirmed" : "cart.recovered" }) as string,
        type: "INFO",
      };
    }
  } else {
    cart = await storeService.getCart();
  }

  dispatch({
    type: cartRecoveryToken ? "CART_RECOVERED" : "CART_LOADED",
    payload: cart,
  });
};

export const reloadCart = async (dispatch: React.Dispatch<Action>): Promise<void> => {
  const cart = await storeService.getCart();
  dispatch({
    type: "CART_LOADED",
    payload: cart,
  });
};

export const addToCart = async (productId: string, quantity: number, dispatch: React.Dispatch<Action>): Promise<Cart> => {
  const cart = await storeService.addToCart({ product_id: productId }, quantity);

  if (typeof window !== "undefined") {
    cartSingleItemPush(cart, productId);
  }
  const additionalData = { productId, quantity };
  dispatch({ type: "ADD_ITEM", payload: { ...cart, additionalData } });
  return cart;
};

export const setQuantity = async (productId: string, lineId: string, quantity: number, dispatch: React.Dispatch<Action>, increasing = true) => {
  let currentCart = await storeService.updateItem({ product_id: productId }, quantity, increasing);
  if (!increasing) {
    const { cart } = await adjustGiftPackingQuantity(currentCart, lineId, quantity);
    currentCart = cart;
  }
  const additionalData = { increasing, productId, quantity };
  dispatch({ type: "SET_QUANTITY", payload: { ...currentCart, additionalData } });
};

export const removeItem = async (existingCart: CartState, productId: string, lineId: string): Promise<Cart> => {
  const newCart = await storeService.removeItems(productId);
  const { cart, cartModified } = await adjustGiftPackingQuantity(existingCart, lineId, 0);
  return cartModified ? cart : newCart;
};

export const applyCoupon = async (couponCode: string, dispatch: Dispatch<Action>) => {
  const newCart = await storeService.applyCoupon(couponCode);
  if (isApiError(newCart)) {
    dispatch({ type: "APPLY_COUPON_FAILED", payload: { errorMessage: translateApiError(newCart) } });
  } else {
    dispatch({ type: "APPLY_COUPON", payload: newCart });
  }
};

export const removeCoupon = async (couponCode: string, dispatch: Dispatch<Action>) => {
  const newCart = await storeService.removeCoupon(couponCode);
  if (isApiError(newCart)) {
    // TODO: handle error
  } else {
    dispatch({ type: "REMOVE_COUPON", payload: newCart });
  }
};

export const getOrderConfirmation = async (orderId: string): Promise<Order | IAPIError> => {
  return await getOrder(orderId);
};

export const isFreeShippingAppliedOrApplicable = (cart: Cart): boolean => {
  return (
    (cart.discounts.some((discount) => discount.type === "FREE_SHIPPING") ||
      cart.promotions.filter((promotion) => promotion.discountType === "FREE_SHIPPING").some((freeShippingPromo) => isPromotionAppliedOrApplicable(freeShippingPromo, cart))) &&
    !(cart.shippingService?.isCod ?? false)
  );
};
