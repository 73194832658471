import { CartDTO } from "./cart";

export interface DataLayerItem {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency: "PLN";
  discount: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  price: number;
  quantity: number;
  product_id: string;
}

const roundNumber = (val: number, scale = 2): number => {
  return Number((Math.round(val * 100) / 100).toFixed(scale));
};

export const toDataLayerItems = (cart: CartDTO | undefined): DataLayerItem[] => {
  if (!cart) {
    throw new Error("Cart must be defined");
  }
  const itemsTotal = cart.items.reduce((acc, item) => acc + item.price_total, 0);
  const globalCoupon = cart.discounts.find((discount) => discount.source.type === "COUPON")?.source.name;

  return cart.items.map((item) => {
    let discountTotal = 0;
    const contributionRatio = item.price_total / itemsTotal;
    let priceTotalAfterDiscount = item.price_total;
    cart.discounts.forEach((discount) => {
      if (discount.type === "FIXED" || discount.type === "PERCENTAGE" || discount.type === "FREE_SHIPPING") {
        const discountValue = discount.amount * contributionRatio;
        discountTotal += discountValue;
        priceTotalAfterDiscount = Math.max(priceTotalAfterDiscount - discountValue, 0);
      } else if (discount.type === "PERCENTAGE_PRODUCT") {
        const discountValue = priceTotalAfterDiscount * discount.source.refValue;
        discountTotal += discountValue;
        priceTotalAfterDiscount = Math.max(priceTotalAfterDiscount - discountValue, 0);
      } else if (discount.type === "FIXED_PRODUCT") {
        const discountValue = discount.amount;
        discountTotal += discountValue;
        priceTotalAfterDiscount = Math.max(priceTotalAfterDiscount - discountValue, 0);
      }
    });

    const saleDiscountDiff = Math.max(item.product.previous_unit_price && item.product.sale ? item.product.previous_unit_price - item.product.unit_price : 0, 0) / (1 + item.product.taxRate);
    const allProductDiscountsNet = discountTotal / (1 + item.product.taxRate);
    const itemNetTotal = Math.max(priceTotalAfterDiscount / (1 + item.product.taxRate), 0);
    // , (item.product.unit_price * item.quantity) / (1 + item.product.taxRate));
    // const itemNetTotal = Math.max((item.price_total - discountTotal) / (1 + item.product.taxRate), 0);
    const coupon = item.discounts.find((discount) => discount.source.type === "COUPON")?.source.name || globalCoupon;

    return {
      item_id: item.product.baselinker_id,
      item_name: item.product.name,
      quantity: item.quantity,
      currency: "PLN",
      price: roundNumber(itemNetTotal / item.quantity),
      discount: roundNumber(allProductDiscountsNet / item.quantity + saleDiscountDiff),
      ...(coupon ? { coupon } : undefined),
      product_id: item.product.product_id,
    };
  });
};
